import * as R from 'ramda';
import { format } from 'date-fns/fp';
import parse from 'url-parse';
import { auth } from 'lib/firebase';
import { getIdTokenResult } from 'firebase/auth';
import { parseISO } from 'date-fns';
import i18next from 'i18next';
import camelCase from 'camelcase';

/**
 * Returns concated string with a space and order depending on the locale.
 * Order for english is stringOne + stringTwo
 * Order for spanish is stringTwo + stringOne
 * using includes for 'es' to account for possible regions
 * and defaulting to En
 * @param {string} stringOne
 * @param {string} stringTwo
 * @param {string} locale 'en-US || es'
 */
export const reorderStrings = (stringOne, stringTwo, locale) => {
  if (locale.includes('es')) {
    return stringTwo + ' ' + stringOne;
  } else {
    return stringOne + ' ' + stringTwo;
  }
};

export const isNotNil = R.complement(R.isNil);

export const isAuthed = user => isNotNil(user);

export const hasSubAppPermissions = (subDomain, app) =>
  R.equals(subDomain, app);

export function formatDateSafely(formatString) {
  return date => {
    try {
      if (!date) {
        throw new Error('Date param is falsy');
      }
      const dateObj = date instanceof Date ? date : parseISO(date);
      return format(formatString)(dateObj);
    } catch (err) {
      return 'N/A';
    }
  };
}

export const calcPercentage = (a, b) => {
  try {
    const numA = parseInt(a, 10);
    const numB = parseInt(b, 10);

    return (numA / numB) * 100;
  } catch (e) {
    return 'N/A';
  }
};

export const shortDate = format('P');

export const longDate = date => formatDateSafely('PP')(date);

export const shortDateWithShortYear = format('MM/dd/yy');

export const shortDateWithLongYear = date => {
  return formatDateSafely('MM/dd/yyyy')(date);
};

export const fullMonthWithYear = dateString => {
  const { t } = i18next;

  if (!dateString) return 'N/A';

  const month = t(
    'common.datetime.months.' + camelCase(formatDateSafely('MMMM')(dateString))
  );

  const year = formatDateSafely('yyyy')(dateString);
  return month + ' ' + year;
};

export const abbrMonthOnly = date => {
  const { t } = i18next;

  if (!date) {
    return 'N/A';
  }

  let dateString = date;

  if (date instanceof Date) {
    dateString = date.toISOString();
  }

  return t(
    'common.datetime.months.' + camelCase(formatDateSafely('MMM')(dateString))
  );
};

export const abbrMonthWithLongYear = date => {
  const { t } = i18next;

  if (!date) {
    return 'N/A';
  }

  let dateString = date;

  if (date instanceof Date) {
    dateString = date.toISOString();
  }

  const month = t(
    'common.datetime.months.' + camelCase(formatDateSafely('MMM')(dateString))
  );
  const year = formatDateSafely('yyyy')(dateString);

  return `${month} ${year}`;
};

export const USD = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

export const getUrlExtension = url => {
  const matches = parse(url).pathname.match(/(\w+)$/g);
  return matches?.length > 0 ? matches[0] : null;
};

export const getEventExtension = location => {
  const pathname = location.pathname;

  if (/dedicated-account/i.test(pathname)) {
    return '_da';
  } else if (pathname === '/' || pathname === '') {
    return '_home';
  } else {
    return '';
  }
};

export const blockIfAgent = fn => async (...args) => {
  const currentUser = auth.currentUser;
  const idTokenResult = await getIdTokenResult(currentUser);
  if (idTokenResult?.claims?.isAgent === true) {
    throw new Error('Agent Unauthorized');
  } else {
    return fn(...args);
  }
};

export const formatToHumanTimestamp = date =>
  new Date(date)
    .toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    })
    .toLocaleLowerCase();

/**
 * Return a formatted phone number
 * '1231231234' -> '(123) 123-1234'
 * '+11231231234' -> '+1 (123) 123-1234'
 * @param {String} phone
 * @returns {String}
 */
export const formatPhoneNumber = (phone = '') => {
  const reverse = str =>
    str
      .split('')
      .reverse()
      .join('');
  if (typeof phone !== 'string' && typeof phone !== 'number') {
    throw new Error('Invalid Phone Number');
  }
  if (typeof phone === 'number') {
    phone = phone.toString();
  }
  // has country code
  if (phone[0] === '+' && phone.length > 11) {
    // remove everything but the plus sign
    phone = `+${phone.replace(/[^\d]/g, '')}`;
    phone = reverse(phone);
    // put the phone back together with the country code +1 (123) 123-1234
    return `${reverse(phone.substring(10, phone.length))} (${reverse(
      phone.substring(7, 10)
    )}) ${reverse(phone.substring(4, 7))}-${reverse(phone.substring(0, 4))}`;
  }
  // else normal condition (123) 123-1234
  phone = phone.replace(/[^\d]/g, '');
  if (phone.length !== 10) {
    throw new Error('Invalid Phone Number');
  }
  return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(
    6,
    10
  )}`;
};

/**
 * Return a formatted phone number
 * but if theres an error it will return the original value
 * '1231231234' -> '(123) 123-1234'
 * @param {String} phone
 * @returns {String}
 */
export const formatPhoneNumberSafe = phone => {
  try {
    return formatPhoneNumber(phone);
  } catch (e) {
    return phone;
  }
};

/**
 * Function to get all of the excluded dates for a date picker.
 *
 * @param excludeDates        Excluded dates from useSelector
 * @param federalHolidays      Federal Holidays from the global config
 * @returns {*}                An array of all excluded dates
 */
export const getAllExcludedDates = (excludeDates, federalHolidays) => {
  let excludedFederalHolidays = [];

  let allYears = [];

  const getParsedHoliday = dateStr => {
    // Adding T00:00:00 forces the Date() to be that day and not a previous day due to Timezone offsets
    return new Date(`${dateStr}T00:00:00`);
  };

  for (let year of Object.keys(federalHolidays)) {
    if (year !== 'every-year') {
      let parsedHolidays = federalHolidays[year].map(dateStr =>
        getParsedHoliday(`${year}-${dateStr}`)
      );
      excludedFederalHolidays = excludedFederalHolidays.concat(parsedHolidays);
      allYears.push(year);
    }
  }

  const everyYearHolidays = federalHolidays['every-year'];

  for (let everyYearHoliday of everyYearHolidays) {
    for (let year of allYears) {
      let parsedHolidays = getParsedHoliday(`${year}-${everyYearHoliday}`);
      excludedFederalHolidays.push(parsedHolidays);
    }
  }

  return excludeDates.concat(excludedFederalHolidays);
};

export const ucFirst = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const militaryToNormal = str => {
  const parts = str.toString().match(/.{1,2}/g);
  const t = new Date();
  const date = new Date(
    t.getFullYear(),
    t.getMonth(),
    t.getDate(),
    parts[0],
    parts[1]
  );
  return format('h:mmaaa', date);
};

export {
  parseRichText,
  replaceVariableNameInContent,
  extractSectionData
} from './contentful';
export { getLawFirmName } from './lawFirmNames';
export { getTestIds } from './dataTestIds';

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/** For strings of the format "Mon - Fri" will translate as
 * required into spanish
 * @param {String} dateRange
 * @returns {String} dateRange in user's language choice
 */
export function translateAbbrDateRange(dateRange) {
  const { t } = i18next;

  dateRange = dateRange.split(' - ');
  const [rangeStart, rangeEnd] = [...dateRange];

  return (
    t(`common.datetime.weekday.${camelCase(rangeStart)}`) +
    ' - ' +
    t(`common.datetime.weekday.${camelCase(rangeEnd)}`)
  );
}

/**
 *
 * This function calculates and returns a string, either a single string date or a range of dates.
 * The dates are in month and year format and can be customized.
 *
 * @param {Object} estimatedSettlementRange - Estimated Settlement Range object
 * @param {string} estimatedSettlementRange.estStart - The start date of the estimated settlement range
 * @param {string} [estimatedSettlementRange.estEnd] - The end date of the estimated settlement range (optional)
 * @param {Function} formatterMonth - A function for formatting the date with the month
 * @param {Function} formatterMonthYear - A function for formatting the date with the month and year
 * @returns {string} - The formatted string representing the date or date range
 */

const formatSettlementRange = (
  estimatedSettlementRange,
  formatterMonth,
  formatterMonthYear
) => {
  if (!estimatedSettlementRange) return;
  const { estStart, estEnd } = estimatedSettlementRange;
  return estEnd
    ? `${formatterMonth(estStart)} - ${formatterMonthYear(estEnd)}`
    : `${formatterMonthYear(estStart)}`;
};

// Returns a string formatted with the abbreviated month and full year,
// either as a single date (e.g., "Jan 2024") or a range of dates (e.g., "Jan 2024 - Dec 2024").
export const getAbbrSettlementRange = estimatedSettlementRange => {
  return formatSettlementRange(
    estimatedSettlementRange,
    abbrMonthWithLongYear,
    abbrMonthWithLongYear
  );
};

// Returns a string formatted with the full month and year,
// either as a single date (e.g., "January 2024") or a range of dates (e.g., "January 2024 - December 2024").
export const getFullSettlementRange = estimatedSettlementRange => {
  return formatSettlementRange(
    estimatedSettlementRange,
    fullMonthWithYear,
    fullMonthWithYear
  );
};

// Returns a string formatted with the abbreviated month only for start date,
// either as a single date (e.g., "Jan 2024") or a range of dates (e.g., "Jan - Dec 2024").
export const getAbbrMonthOnlySettlementRange = estimatedSettlementRange => {
  return formatSettlementRange(
    estimatedSettlementRange,
    abbrMonthOnly,
    abbrMonthWithLongYear
  );
};
