import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { processFlags, useLaunchDarkly } from 'lib/hooks';
import { getAppSubDomain, logger } from 'shared-components';
import { getPtS, getUserRecord } from 'shared-selectors/userRecord';
import { useQuery } from 'react-query';
import updateFirestoreUserRecord from '../api/updateFirestoreUserRecord';
import { analyticsHotjarIdentify } from 'lib/utils/analytics';
import { PTS_VARIATIONS } from 'shared-components/PtS/assets/constants.js';

const LOGGING_PREFIX = `PtS :: usePathToSettlementEligibility ::`;

const updateLdExperiments = async (uid, value) => {
  try {
    await updateFirestoreUserRecord(uid, {
      ldExperiments: {
        ptsMvpV2: value
      }
    });
  } catch (error) {
    logger.error(
      `${LOGGING_PREFIX} Error updating Firestore user record (${uid}) for PtS experiment value (${value}): `,
      error
    );
  }
};

// Declaring this outside the component so that we don't have to worry about re-renders in our useEffect
const updateSessionCount = async (uid, ptsSessionCount) => {
  const updatedCountValue = ptsSessionCount ? ptsSessionCount + 1 : 1;
  try {
    await updateFirestoreUserRecord(uid, {
      pts: {
        ptsSessionCount: updatedCountValue
      }
    });
  } catch (err) {
    logger.error(
      `${LOGGING_PREFIX} Error updating Firestore user record (${uid}) for PtS session count. This is a non-critical error and can be ignored.`
    );
  }
};

/**
 * Determines if a user is eligible for a specific feature (PTS) based on LaunchDarkly flags and updates the user's Firestore record accordingly.
 * @returns {boolean} Returns true if the user is eligible for the PTS feature, otherwise false.
 */
export const usePathToSettlementEligibility = () => {
  const { flags, activeFlagNames, fetchSingleFlag } = useLaunchDarkly();
  const subdomain = getAppSubDomain();
  const userRecord = useSelector(getUserRecord || {});
  const { createdAt, fdrApplicantId, ldExperiments, uid } = userRecord;
  const [ptsEligible, setPtsEligible] = useState(null);
  const [ptsFlag, setPtsFlag] = useState(null);
  const flagValue = flags?.[activeFlagNames.ptsMvp];
  const previousVisitPtSValue = ldExperiments?.ptsMvpV2;
  const ptsFireStoreData = useSelector(getPtS);
  const ptsSessionCount = ptsFireStoreData?.ptsSessionCount;

  const { isLoading: fetchingPtSFlagFromLD } = useQuery(
    'fetchPtSFlag',
    async () => {
      logger.info(
        `${LOGGING_PREFIX} Requesting LD flag for PtS for user ${uid}`
      );
      fetchSingleFlag(activeFlagNames?.ptsMvp, {
        createdAt: new Date(createdAt).toISOString(), // Passing an ISO string here so that we can give it to LD in a way that can be compared
        brand: subdomain,
        fdrApplicantId
      });
    },
    {
      // Only check LD once we have a user uid, which is a proxy for the user object being populated with data
      // and don't fetch if previousVisitPtSValue, for temporary new logic
      enabled: !!uid && !previousVisitPtSValue,
      staleTime: Infinity, // Data is considered fresh forever
      onSuccess: () => {
        logger.info(
          `${LOGGING_PREFIX} PtS Flag evaluated for fdrApplicantId (${fdrApplicantId}).`
        );
      },
      onError: error => {
        logger.error(`${LOGGING_PREFIX} Error fetching Pts single flag`, error);
      }
    }
  );

  // Not assigning any variables as this is not a client facing call
  // and the retry logic is handled within useQuery and analyticsHotjarIdentify
  useQuery(
    [
      'hotjarIdentifyPTS',
      createdAt,
      fdrApplicantId,
      previousVisitPtSValue,
      ptsSessionCount
    ],
    () =>
      analyticsHotjarIdentify(fdrApplicantId, {
        accountCreationDate: createdAt,
        ptsExperimentVariant: previousVisitPtSValue,
        ptsSessionCount
      }),
    {
      enabled:
        !!createdAt &&
        !!fdrApplicantId &&
        !!previousVisitPtSValue &&
        !!ptsSessionCount,
      staleTime: Infinity,
      retry: 1
    }
  );

  // Update a user's Firestore record if the LaunchDarkly flag has been requested and evaluated
  // Otherwise, if there's a previousVisitPtSValue set, set their eligibility based on that value
  useEffect(() => {
    // Don't do anything if we're checking LD
    if (fetchingPtSFlagFromLD) return;

    if (previousVisitPtSValue) {
      // If we do have a previousVisitPtS
      logger.info(
        `${LOGGING_PREFIX} Setting PtS eligibility based on cached value (${previousVisitPtSValue}). LD call was not made to get flagValue.`
      );
      setPtsFlag(previousVisitPtSValue);
      setPtsEligible(
        previousVisitPtSValue === PTS_VARIATIONS.SEE_PTS_TEST_1 ||
          previousVisitPtSValue === PTS_VARIATIONS.SEE_PTS_TEST_2
      );
      // Throw away call. Don't do anything if we can't update the session count, as it's not mission-critical
      updateSessionCount(uid, ptsSessionCount);
      // adds pts flag to experiments array so the flag is included in event horizon payload
      const ptsFlag = {
        'cx-pts-on-clients-dashboard-homepage': {
          value: {
            experimentName: 'cx-pts-on-clients-dashboard-homepage',
            experimentId: 'cx-pts-on-clients-dashboard-homepage',
            variation: previousVisitPtSValue
          },
          variationIndex: 0,
          reason: {
            kind: 'MANUAL'
          }
        }
      };
      // NOTE: This is handled automatically when fetching the flags from LD
      processFlags(ptsFlag);
    } else if (!previousVisitPtSValue && flagValue) {
      logger.info(
        `${LOGGING_PREFIX} LD flag returned with (${flagValue}). Caching in user record.`
      );
      // TODO: What if this fails? Will the user be evaluated by LD again? And if so, will they be bucketed in the same group so long as the distribution hasn't changed?
      updateLdExperiments(uid, flagValue)
        .then(() => {
          setPtsFlag(flagValue);
          setPtsEligible(
            flagValue === PTS_VARIATIONS.SEE_PTS_TEST_1 ||
              flagValue === PTS_VARIATIONS.SEE_PTS_TEST_2
          );
          // Throw away call. Don't do anything if we can't update the session count, as it's not mission-critical
          updateSessionCount(uid, ptsSessionCount);
        })
        .catch(err => {
          // Logging for visibility into failure writing to user-record collection
          logger.error(
            `${LOGGING_PREFIX} Error persisting PtS flag (${flagValue}) from LD for user (${uid})`,
            err
          );
        });
    } else {
      // This is for fall-through debugging. Ideally, we won't ever see this log.
      logger.info(
        `${LOGGING_PREFIX} No previous value in userRecord and LD has not been evaluated for user (${uid}). This could indicate a logic problem.`
      );
    }
  }, [
    uid,
    fetchingPtSFlagFromLD,
    flagValue,
    previousVisitPtSValue,
    ptsSessionCount
  ]);

  return { ptsEligible, ptsFlag };
};
