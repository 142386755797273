import { useCallback, useEffect, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth as useFirebaseAuth } from 'lib/hooks/useAuth';
import { authTokenExchange } from 'lib/api/authTokenExchange';
import { getAppSubDomain } from 'shared-components';
import { extractFdrApplicantId, extractIdBundle } from 'lib/utils/auth0';
import { BUSINESS_ID_MAP } from 'constants';

/***
 * This hook is used to unify the authentication process between Auth0 and Firebase.
 * We're in a position to use both because we're using Auth0 for initial authentication/login
 * and Firebase for authorization/permissions.
 *
 * We need to check if the user has a valid auth session with Firebase or Auth0
 */
export function useUnifiedAuth(authCallback) {
  const subdomain = getAppSubDomain();
  const auth0 = useAuth0();
  const firebase = useFirebaseAuth();
  const businessId = BUSINESS_ID_MAP[subdomain] || '';

  const isCheckingAuth = useRef(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [hasAuth0Token, setHasAuth0Token] = useState(null);
  const [fdrApplicantId, setFdrApplicantId] = useState(null);
  const [error, setError] = useState(false);

  const auth0User = auth0?.user;

  // Based on subdomain/brand, navigate to the correct path for the signin app
  const redirectToBrandSpecificLogin = useCallback(() => {
    let rootUrl = null;
    let path = '/login';

    switch (subdomain) {
      case 'fdr':
        rootUrl = process.env.REACT_APP_FDR_ROOT_URL;
        break;
      case 'turnbull':
        rootUrl = process.env.REACT_APP_TURNBULL_ROOT_URL;
        break;
      case 'achieve':
        rootUrl = process.env.REACT_APP_ACHIEVE_ROOT_URL;
        path = '/signin';
        break;
      default:
        // If our subdomain doesn't match any of the above
        // we'll want to throw here to be extra certain we don't
        // try to redirect the user to the wrong app or brand's signin page
        throw new Error(
          `Cannot redirect user to sign-in app. Invalid subdomain ${subdomain}`
        );
    }
    // If this is falsy, we also want to throw here because we couldn't find the rootUrl
    // Could have been an unset .env variable or a bad lawFirm subdomain.
    if (!rootUrl)
      throw new Error(
        `Cannot redirect user to sign-in app. Invalid rootUrl ${rootUrl}`
      );

    window.location.assign(`${rootUrl}${path}?url=${window.location.href}`);
  }, [subdomain]);

  /****
   * TODO: THIS EFFECT RUNS TWICE ON PAGE LOAD
   * THERE'S NO NEED FOR IT TO RUN TWICE, HOWEVER THERE IS NO VISIBLE IMPACT TO THE END USER
   * NEED TO REVISIT THIS AND FIGURE IT OUT
   *
   * Ultimately dependent on useAuth() file, which sets up all Firebase specific functions, but that file has a dep on Redux
   */
  useEffect(() => {
    // If we're already authenticated, we don't need to do anything
    if (isAuthenticated === true) return;
    // If we're already checking auth, we don't need to do anything
    // This prevents multiple calls to getAccessTokenSilently()
    if (isCheckingAuth.current === true) return;

    const tokenExchange = async () => {
      // Set our ref so that we know we're performing token exchange
      isCheckingAuth.current = true;

      try {
        // Attempt to get a token from Auth0;
        // If we have a valid session, we'll get a token back
        // If not, we'll get an error
        const token = await auth0.getAccessTokenSilently({
          timeoutInSeconds: 5
        });

        setHasAuth0Token(true);

        // If we have a token, we can exchange it for a Firebase token
        if (token) {
          // Call token exchange endpoint on ffn-cx-api
          const { firebaseToken } = await authTokenExchange(token);
          // Sign in to Firebase using client-side SDK with the token we got back
          await firebase.signinWithCustomToken(firebaseToken);

          // If we got an authCallback passed, call it
          if (authCallback && typeof authCallback === 'function') {
            await authCallback();
          }

          // And we're good! Set our state to authenticated
          setIsAuthenticated(true);
          return;
        }
      } catch (err) {
        // Specifically check for login required error, which is what we get if getAccessTokenSilently() fails
        const isLoginRequired = err.toString() === 'Error: Login required';
        // Specifically check for an MFA required error, which is what happens if user returns
        // to app after entering their credentials but skipped MFA
        const multifactorRequired =
          err.toString() === 'Error: Multifactor authentication required';

        // An unspecified error occurred, so we'll return an error state to the UI
        if (!isLoginRequired && !multifactorRequired) {
          console.log(err);
          setError({
            message: 'An error occurred while attempting to fetch Auth0 token'
          });
          return;
        }

        // If the user is visiting a restricted route and needs to login or authenticate with MFA,
        // we'll perform a hard redirect to the appropriate login app by calling the exported callback in
        // the <RouteGuard /> component. This allows us to keep certain routes available to non-logged-in users.
        setHasAuth0Token(false);
      } finally {
        isCheckingAuth.current = false;
      }
    };

    // Actually run our async token exchange function
    tokenExchange();

    // Only want to run this once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // analytics (a vanilla JS component) requires the auth0_user_id and
    // the profile_id, so placing in sessionStorage
    const idBundle = extractIdBundle(auth0User);
    const stringifiedData = JSON.stringify(idBundle);
    sessionStorage.setItem('idBundle', stringifiedData);
    return () => {
      sessionStorage.removeItem('idBundle');
    };
  }, [auth0User]);

  // email and fdrApplicantId extracted and exposed for any use case where
  // the user record is not available (eg: a data issue related to the
  // user record or bootstrap)
  const email = auth0?.user?.email;

  useEffect(() => {
    if (auth0User && businessId) {
      setFdrApplicantId(extractFdrApplicantId(auth0User, businessId));
    }
  }, [auth0User, businessId]);
  return {
    fdrApplicantId,
    email,
    isAuthenticated: isAuthenticated,
    hasAuth0Token,
    redirectToBrandSpecificLogin,
    user: firebase.user,
    error
  };
}
